// Generated by Framer (b75150a)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["OfI7tmbYL"];

const serializationHash = "framer-qSWcc"

const variantClassNames = {OfI7tmbYL: "framer-v-10ls95t"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "OfI7tmbYL", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-10ls95t", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"OfI7tmbYL"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backdropFilter: "blur(8px)", backgroundColor: "rgba(38, 38, 38, 0.9)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.07), 0px 11px 10px 0px rgba(0, 0, 0, 0.1), 0px 20px 18px 0px rgba(0, 0, 0, 0.13), 0px 36px 32px 0px rgba(0, 0, 0, 0.15), 0px 68px 60px 0px rgba(0, 0, 0, 0.18), 0px 160px 140px 0px rgba(0, 0, 0, 0.25)", WebkitBackdropFilter: "blur(8px)", ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-qSWcc.framer-1tf4o4k, .framer-qSWcc .framer-1tf4o4k { display: block; }", ".framer-qSWcc.framer-10ls95t { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 16px; height: min-content; justify-content: center; min-height: 19px; overflow: visible; padding: 0px; position: relative; width: 63px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-qSWcc.framer-10ls95t { gap: 0px; } .framer-qSWcc.framer-10ls95t > * { margin: 0px; margin-bottom: calc(16px / 2); margin-top: calc(16px / 2); } .framer-qSWcc.framer-10ls95t > :first-child { margin-top: 0px; } .framer-qSWcc.framer-10ls95t > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 19
 * @framerIntrinsicWidth 63
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerlh9k2EcFz: React.ComponentType<Props> = withCSS(Component, css, "framer-qSWcc") as typeof Component;
export default Framerlh9k2EcFz;

Framerlh9k2EcFz.displayName = "ÀreasCiência";

Framerlh9k2EcFz.defaultProps = {height: 19, width: 63};

addFonts(Framerlh9k2EcFz, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})